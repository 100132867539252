import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import SectionHeaderSlider from "../SectionHeaderSlider";
import ProjectLayoutSearchForm from './ProjectLayoutSearchForm.js';

import ProjectLayoutTemplateTypeSelect from './ProjectLayoutTemplateTypeSelect.js';
import ProjectLayoutTemplateSelect from './ProjectLayoutTemplateSelect.js';
import ProjectLayoutBuildingSelect from './ProjectLayoutBuildingSelect.js';

import ProjectLayoutPositions from './ProjectLayoutPositions.js';
import ProjectLayoutSpacesSet from './ProjectLayoutSpacesSet.js';
import ProjectLayoutTemplateUpload from './ProjectLayoutTemplateUpload.js';
import LoadingLayoutGeneration from '../LoadingLayoutGeneration.js';
import LoadingBuildingSearch from '../LoadingBuildingSearch.js';
import LoadingFull from '../LoadingFull';


import { findBuildingsParameters,getProjectM2Data, 
  generateWorkspacesM2, getSpace, getProjectBuildingData, 
  createLayout, checkLayoutJobProgress, getFinishedJob, getBuildingByZone,
getLayout, putLayout} from "../../api.js";

export default class ProjectLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {           
          params: {
            country_id: "",
            region_id: "",
            zone_id: "",
          },
          workspaces: null,
          workers_number: null,
          building_data: null,   
          selected_floor: null,    
          loading_zone_buildings: false, 
          loading: true,
          
          //selected_building: JSON.parse('{"active":true,"address_number":"4580","building_images":[{"link":"http://wysapi.ac3eplatforms.com/api/filestorage/ZWRpZmljaW8xLnBuZzE1OTUzNDI3NjcuNDU0MzIx.png"},{"link":"http://wysapi.ac3eplatforms.com/api/filestorage/ZWRpZmljaW8xLnBuZzE1OTUzNDI3NjcuNDU0MzIx.png"},{"link":"http://wysapi.ac3eplatforms.com/api/filestorage/ZWRpZmljaW8yLnBuZzE1OTUzNDI3NTAuNjcwNDQ2.png"}],"building_year":2002,"category":"Clase A","floors":[{"active":true,"building_id":9,"elevators_number":4,"id":17,"image_link":"http://wysdev.ac3eplatforms.com/api/filestorage/NjNhZjIwMjYzN2ZkMjVmZDkzNzI5MzEwYTAxOTQwYTFmZTA2ZTk5ZS5wbmcxNjAxNjU5NzI2LjUwODk5MQ==.png","m2":642.72,"rent_value":5353,"wys_id":"CL17"},{"active":true,"building_id":9,"elevators_number":5,"id":37,"image_link":null,"m2":1077,"rent_value":8000,"wys_id":"CL03LMK"}],"gps_location":"-33.404417, -70.582222","id":9,"infrastructure_lvl":7,"name":"Edificio Vista 360","parking_lvl":8,"parking_number":150,"public_transport_lvl":9,"renters":[],"security_lvl":9,"services_lvl":10,"street":"Alonso de Córdova","sustainability_lvl":9,"total_floors":12,"view_lvl":8,"zone_id":3}')
        };
    }
    componentDidMount() {
        //this.props.redirect("/proyecto/" + this.props.project_id + "/layout/search");
        //this.props.redirect("/proyecto/" + this.props.project_id + "/layout/building_select");
        this.props.redirect("/proyecto/" + this.props.project_id + "/layout");
        //this.props.redirect("/proyecto/" + this.props.project_id + "/layout/positions");
        if(this.props.project && typeof this.props.project !== "string") {
          setTimeout(()=> {
            getLayout(this.props.project_id, (error, result)=> {
                if(result) {
                this.loadData();  
                this.setState({generated_layout: result});
  this.props.redirect("/proyecto/" + this.props.project_id + "/layout/positions");
                } else {

                  this.loadData();

                }
            });

          }, 2000);
        }
    }
    componentDidUpdate(prevProps) {
      if((!prevProps.project ||typeof prevProps.project === "string") && this.props.project && this.props.project.id) {
        this.loadData();   
      }
    }
    saveLayout() {
      this.setState({loading: true});
      putLayout(this.props.project_id, this.state.generated_layout.workspaces.map(w=>{
        return{
          space_id: w.space_id, 
          id: w.id, 
          rotation: w.rotation, 
          position_x: w.position_x, 
          position_y: w.position_y, 
          width: w.width,
          height: w.height,
          color: w.color, 
          alias: w.alias}}), (error, result) => {
        this.props.redirect("/proyecto/" + this.props.project_id + "/layout/done");
      });
    }
    loadData() {
      setTimeout(()=> {
        findBuildingsParameters((error, result) => {
            if(error) { 
              return console.log("error cargando parametros 'ProjectLayout: loadData'")
            }
              this.setState({countries: result});
              setTimeout(()=> {
                this.loadBuildingData();    
                this.generateWorkspaces(() => {
                  setTimeout(()=> {
                    this.loadM2Data();     
                    this.setState({loading: false}) ;

                  },2000);

                });

              },2000);
        });  

      }, 2000);
    }

  generateWorkspaces(cb) {
    this.setState({ workspaces: "loading" })
    console.log("Workspaces generados");
    var data = {
      "collaboration_level": 40,
      "hotdesking_level": 85,
      "num_of_workers": 86,
      "area": 602
    };
    var min_bathrooms = 0;

    generateWorkspacesM2(data, (error, result) => {
      if (error) {
        return console.log("error");
      }
      result.workspaces.forEach((w) => {
        w.subcategories.forEach((s) => {
          if (s.spaces && s.spaces.length > 0) s.selectedSpace = s.spaces[0];
          else s.selectedSpace = null;
          s.generated_quantity = s.quantity;
          if (s.generated_quantity > 0) s.toggled = true;
          if (s.name === "Baños") {
            min_bathrooms += s.selectedSpace.quantity;
            /*s.spaces.forEach((s)=>{
            });*/
            //min_bathrooms = s.selectedSpace.quantity;
          }
        });
      });
      this.setState({ workspaces: result.workspaces, min_bathrooms }, () => {
        this.loadSpacesImages();
        if (cb) cb();

        this.getMinBathrooms();
      });
    });
  }

  getMinBathrooms() {
    var min_bathrooms = 0;
    this.state.workspaces.forEach((w) => {
      w.subcategories.forEach((s) => {
        if (s.spaces && s.spaces.length > 0) s.selectedSpace = s.spaces[0];
        else s.selectedSpace = null;
        s.generated_quantity = s.quantity;
        if (s.generated_quantity > 0) s.toggled = true;
        if (s.name === "Baños") {
          min_bathrooms += s.selectedSpace.quantity;
          /*s.spaces.forEach((s)=>{
          });*/
          //min_bathrooms = s.selectedSpace.quantity;
        }
      });
    });
      return this.setState({min_bathrooms});
    }

    loadM2Data() {
        getProjectM2Data(this.props.project.id, (error, result)=> {
            if(result) {
              var workspaces = [...this.state.workspaces];
              if(result.m2_generated_data.workers_number){
                this.setState({'workers_number':result.m2_generated_data.workers_number});
              }
              result.m2_generated_data.workspaces.forEach((space)=> {
                workspaces.forEach((ws)=> {
                  ws.subcategories.forEach((sc)=> {
                    sc.spaces.forEach((s)=> {
                      if(s.id === space.space_id) {
                        s.quantity = space.quantity;
                      }
                    });
                  });

                });
              });
              this.setState({workspaces});

            }
        });
    } 
    loadBuildingData() {
        getProjectBuildingData(this.props.project.id, (error, result)=> {
            this.setState({building_data: result});
        });
    } 
    updateSpacePosition(space_id, position) {
      var workspaces = this.state.generated_layout.workspaces.map((space)=> {
        if(space.id === space_id) 
          return {...space, position_x: position.x, position_y: position.y };
        return space;
      });
      var generated_layout = {...this.state.generated_layout}
      generated_layout.workspaces = workspaces;
      this.setState({generated_layout});
    }
    rotateSpace(space_id) {
     var workspaces = this.state.generated_layout.workspaces.map((space)=> {
        if(space.id === space_id) {
          var rotation = "0";
            if(space.rotation === "0") rotation = "90";
            else if(space.rotation === "90") rotation = "180";
            else if(space.rotation === "180") rotation = "270";
            return {...space, rotation };
        }
        return space;
      });
      var generated_layout = {...this.state.generated_layout}
      generated_layout.workspaces = workspaces;
      this.setState({generated_layout}); 
    };
    setSpaceColor(space_id, color) {
     var workspaces = this.state.generated_layout.workspaces.map((space)=> {
        if(space.id === space_id) {          
            return {...space, color };
        }
        return space;
      });
      var generated_layout = {...this.state.generated_layout}
      generated_layout.workspaces = workspaces;
      this.setState({generated_layout}); 
    };
    setSpaceAlias(space_id, alias) {
     var workspaces = this.state.generated_layout.workspaces.map((space)=> {
        if(space.id === space_id) {          
            return {...space, alias };
        }
        return space;
      });
      var generated_layout = {...this.state.generated_layout}
      generated_layout.workspaces = workspaces;
      this.setState({generated_layout}); 
    };
    changeSpace(space_id, new_space, cb) {      
      var new_space_full = null;
      var workspaces = this.state.generated_layout.workspaces.map((space)=> {
        if(space.id === space_id) {    
          console.log("############ Space a reemplazar");      
          console.table(space);
          console.log("############ Space nuevo");            
          console.table(new_space);
            new_space_full = {
              ...space, 
              space_id: new_space.id, 
              height: new_space.height, 
              width: new_space.width, 
              image:new_space.model_2d
               };
              console.log("############ space final");
              console.table(new_space_full);
            return new_space_full;
        }
        return space;
      });
      var generated_layout = {...this.state.generated_layout}
      generated_layout.workspaces = workspaces;
      this.setState({generated_layout}, ()=>cb(new_space_full)); 
    }
    loadSpacesImages() {
        var spaces_images = [];
        var workspaces = [...this.state.workspaces];
        workspaces.forEach((workspace) => {
            workspace.subcategories.forEach((sub) => {
                sub.spaces.forEach((space) => {
                    spaces_images.push({id: space.id});
                });                
            });                
        });
        this.setState({spaces_images}, () => {
            spaces_images.forEach((space)=> {
                getSpace(space.id, (error, result) => {
                    if(error) return console.log("error cagando space", space, error);
                    this.addSpaceImage(space.id, result.model_3d, result.height*result.width, result);
                    if(this.props.m2_data) {
                        this.props.m2_data.m2_generated_data.workspaces.forEach((saved_ws) => {
                            if(saved_ws.space_id === space.id) {
                                this.updateSubWorkspaceValues(space.id, saved_ws.quantity);
                            }

                        });
                    }

                });
            });                   
        });
    }
    addSpaceImage(space_id, base64_image, area, raw) {
        var spaces_images = [...this.state.spaces_images];
        spaces_images.forEach((space) =>{
            if(space.id === space_id) {
                space.image = base64_image;
                space.area = area;
                space.raw = raw;
            }
        });
        this.setState({spaces_images});
    }       
       
    setParameter(parameter, value, cb) {
        var params = { ...this.state.params };
        params[parameter] = value;
        this.setState({ params }, cb);
    }
    //SPACES SET
    selectSpace(workspace, space) {        
        var workspaces = [...this.state.workspaces];
        workspaces.forEach(ws=> {
            if(ws.id === workspace.main_id) {
                ws.subcategories.forEach(sws=> {
                        if(sws.id === workspace.id) sws.selectedSpace = {id: space};
                });
            }
         })
        this.setState({workspaces});       
    }    
    updateSpaceCount(workspace, subcategory, space, count) {
        if(count < 0) return;
        var workspaces = [...this.state.workspaces];
        workspaces.forEach(ws=> {
            if(ws.id === workspace.id) {
                ws.subcategories.forEach(sws=> {
                        if(sws.id === subcategory.id) {
                            if(sws.name === "Baños") {
                                var total = 0; //baños
                                //checkeamos ue no pase el minimo
                                sws.spaces.forEach(s=> {
                                    if(s.id === space.id) {
                                        total += parseInt(count);
                                    } else {
                                        total += s.quantity;
                                    }
                                });
                                if(total < this.state.min_bathrooms) return; //se suspende
                            }
                            sws.spaces.forEach(s=> {
                                    if(sws.name === "Baños") {
                                    }
                                    if(s.id === space.id) s.quantity = parseInt(count);
                            });
                        }
                });
            }
         })            
        this.setState({workspaces});
    }
    toggleSubworkspace(workspace) {
        var workspaces = [...this.state.workspaces];
        workspaces.forEach(ws=> {
            if(ws.id === workspace.main_id) {
                ws.subcategories.forEach(sws=> {
                        if(sws.id === workspace.id) sws.toggled = !sws.toggled;
                });
            }
         })
        this.setState({workspaces});
    }
    //END SPACES SET
    //Positions
    generateLayout() {
      this.setState({generation_progress: {job_status: "queued", progress: 0}}, () => {
        
        var selected_spaces = [];
        this.state.workspaces.forEach((w, i) => {
            if(w.subcategories) {
                w.subcategories.forEach((sc, j) => {
                    if(sc.spaces) {
                        sc.spaces.forEach((space)=> {
                            if(space.quantity > 0) {
                              this.state.spaces_images.forEach((si)=> {
                                if(si.id === space.id) {
                                  var final_space = {...si.raw, quantity: space.quantity, points: [{x: 0, y: 0}]};
                                  delete final_space.model_3d;
                                  delete final_space.model_2d;

                                 selected_spaces.push(final_space);     
                                }
                              }); 
                            }
                        });
                    }
                });
            }
        });
        var data = {
          "selected_floor": {...this.state.selected_floor, /*id: 55, building_id: 72, image_link: "http://wysdev.ac3eplatforms.com/api/filestorage/cGxhbnRhLnBuZzE2MDAwNDYzODcuMzEwMjAy.png"*/},
          "workspaces": selected_spaces
        };
        createLayout(this.props.project.id, data, (error, result) => {
          if(error) {
            console.log("error solicitando generacion de layout", error);
            return alert("error solicitando generacion de layout");
          }
          this.checkLayoutJobProgress(result.job_id);        
        });
      });
    }
    checkLayoutJobProgress(job_id) {
      this.progressInterval = setInterval(() => {
        checkLayoutJobProgress(job_id, (error, result) => {
              if(error) {
                console.log("error solicitando job progress", error);
                return alert("error solicitando job progress");
              }
              if(result.job_status === "finished") {
                this.setState({generation_progress: {...result, progress: 100}}, () => {
                  clearInterval(this.progressInterval);
                  this.getFinishedJob(job_id);

                });
              } else this.setState({generation_progress: result});
        });                
      }, 3000);
    }
    getFinishedJob(job_id) {
      getFinishedJob(job_id, this.props.project_id, (error, result)=> {
        //console.log("RESULT", error, result);
        this.setState({generated_layout: result, generation_progress: null}, () => {
          this.props.redirect("/proyecto/" + this.props.project_id + "/layout/positions");
        });
      });

    }
    //End Positions
    continue(selected) {
        if(!selected) return;
        if(selected === 1) this.setState({selected_floor: this.state.building_data.selected_building.selected_floor}, () => {
          this.props.redirect("/proyecto/" + this.props.project_id + "/layout/set");
        })
        if(selected === 2) this.props.redirect("/proyecto/" + this.props.project_id + "/layout/search");        
        if(selected === 3) this.props.redirect("/proyecto/" + this.props.project_id + "/layout/upload");
    }
    goToFloorSelect(building) {
      if(building) {
        this.setState({selected_building: building}, () => {
          this.props.redirect("/proyecto/" + this.props.project_id + "/layout/select_template");

        });
      }
      else if(this.state.params.zone_id) {
          this.setState({loading_zone_buildings: true}, () => {
            getBuildingByZone(this.state.params.zone_id, (error, result) => {
              this.setState({zone_buildings: result}, () => {
                this.props.redirect("/proyecto/" + this.props.project_id + "/layout/building_select", () => {
                  this.setState({loading_zone_buildings: false});
                });                
              });
            });
          });
      }
      else alert("debe seleccionar opcion");
    }
    selectFloor(floor) {
      this.setState({selected_floor: floor}, () => {

        this.setState({ workspaces: "loading" })
        this.setState({ loading: true })

        var data = {
          "collaboration_level": 40,
          "hotdesking_level": 85,
          "num_of_workers": this.state.selected_floor.m2/7,
          "area": this.state.selected_floor.m2
        };

        generateWorkspacesM2(data, (error, result) => {
          if (error) {
            return console.log("error");
          }

          this.setState({ workspaces: result.workspaces });
          this.setState({ loading: false })
          this.props.redirect("/proyecto/" + this.props.project_id + "/layout/set");

        });
      })
    }

    toggleZoneBuilding(building) {    
        if(this.state.zone_building) {
          if(this.state.zone_building.id === building.id) {
            building = null
          }
        }
        this.setState({zone_building: building});

    }
    updateZoneBuildingHover(building, value) {
        var zone_buildings = this.state.zone_buildings.map((b) => {
            b = {...b};
            if(building.id === b.id) {
                b.hover=value;
            }
            return b;
        });
        this.setState({zone_buildings});

    }
    doneSelectingBuilding() {
      if(!this.state.zone_building) return alert("debes seleccionar un edificio");
      this.setState({selected_building: this.state.zone_building}, () => {
        this.props.redirect("/proyecto/" + this.props.project_id + "/layout/select_template");

      });
    }
    render() {
        var project = this.props.project;
        if (!project || project === "loading") return null;
        if (this.state.generation_progress) {
            return <LoadingLayoutGeneration generation_progress={this.state.generation_progress} />;
        };
        if (this.state.loading_zone_buildings) {
            return <LoadingBuildingSearch />;
        };
        if(this.state.loading) return <LoadingFull />;
        return (
            <Fragment>
                <SectionHeaderSlider
                    section="layout"
                    options={[
                        "/proyecto/" + project.id + "/layout",
                        "/proyecto/" +
                            project.id +
                            "/layout/set"                        
                    ]}
                    selectedOption={this.props.location.pathname}
                />
                <div className="main-content-logged layout">
                    
                        <Switch>
                            <Route exact path="/proyecto/:id/layout"
                              render={(data)=> {   
                                  return(                                  
                                      <ProjectLayoutTemplateTypeSelect     
                                          continue={this.continue.bind(this)}           
                                          workspaces={this.state.workspaces}
                                          building_data={this.state.building_data}
                                          project={project} />
                                      );
                              }
                            }/>
                            <Route exact path="/proyecto/:id/layout/search"
                              render={(data)=> {                
                                  return(
                                    <ProjectLayoutSearchForm
                                      goToFloorSelect={this.goToFloorSelect.bind(this)}
                                      params={this.state.params}
                                      setParameter={this.setParameter.bind(this)}
                                      //setBuildings={(buildings, cb) => {this.setState({buildings}, cb)}}
                                      redirect={this.props.redirect}  
                                      countries={this.state.countries}
                                      project={project} />);
                              }
                            }/>
                            <Route exact path="/proyecto/:id/layout/select_template"
                              render={(data)=> {                
                                  return(                                    
                                      <ProjectLayoutTemplateSelect                
                                          workspaces={this.state.workspaces}
                                          floors={this.state.selected_building.floors}
                                          selectFloor={this.selectFloor.bind(this)}
                                          project={project} />
                                      );
                              }
                            }/>
                            <Route exact path="/proyecto/:id/layout/building_select"
                              render={(data)=> {   
                                // console.log("zone buildings", JSON.stringify(this.state.zone_buildings));
                                  return(                                    
                                      <ProjectLayoutBuildingSelect
                                          buildings={this.state.zone_buildings}
                                          toggleBuilding={this.toggleZoneBuilding.bind(this)}
                                          updateBuildingHover={this.updateZoneBuildingHover.bind(this)}
                                          zone_building={this.state.zone_building}
                                          doneSelectingBuilding={this.doneSelectingBuilding.bind(this)}
                                          project={project} />
                                      );
                              }
                            }/>
                            <Route exact path="/proyecto/:id/layout/upload"
                              render={(data)=> {                
                                  return(                                    
                                      <ProjectLayoutTemplateUpload 
                                        project={project}
                                      />
                                      );
                              }
                            }/>
                            <Route exact path="/proyecto/:id/layout/set"
                              render={(data)=> {    
                                  return(
                                <div className="content">

                                <ProjectLayoutSpacesSet                                  
                                  workspaces={this.state.workspaces}                        
                                  project={project}
                                  spaces_images={this.state.spaces_images} 
                                  toggleSubworkspace={this.toggleSubworkspace.bind(this)}
                                  selectSpace={this.selectSpace.bind(this)} 
                                  updateSpaceCount={this.updateSpaceCount.bind(this)}   
                                  generateLayout={this.generateLayout.bind(this)}     
                                  getMinBathrooms={this.getMinBathrooms.bind(this)}                         
                                   /></div>);            
                                    
                                  }
                                }/>   
                            <Route exact path="/proyecto/:id/layout/positions"
                              render={(data)=> {    
                                  return(
                                    <ProjectLayoutPositions       
                                        save={this.saveLayout.bind(this)}         
                                          project={project}
                                          generated_layout={this.state.generated_layout}
                                          updateSpacePosition={this.updateSpacePosition.bind(this)}
                                          rotateSpace={this.rotateSpace.bind(this)}
                                          setSpaceColor={this.setSpaceColor.bind(this)}
                                          setSpaceAlias={this.setSpaceAlias.bind(this)}
                                          changeSpace={this.changeSpace.bind(this)}
                                          workers_number={this.state.workers_number}

                                           />
                                          
                                      );
                            }}/>
                          
                        </Switch>
                </div>
                 
            </Fragment>
        );
    }
}
