var prod_urls = {
	auth_backend: "https://backcontract.want.cl/api",
	backend: "https://wysapi.ac3eplatforms.com/api"
}
var dev_urls = {
	auth_backend: "https://backoffice.workplacebyyourself.com/api",
	backend: "https://api.workplacebyyourself.com/api"	
}

export var urls = dev_urls;
export var params = {
	facebook_app_id: 304052201400449,	
	google_app_id: "456820608431-322pks0ie0ttdmfigfhkuolj9tpsrtcu.apps.googleusercontent.com" 

};
export var params2 = {
	facebook_app_id: 304052201400449,
	google_app_id: "456820608431-322pks0ie0ttdmfigfhkuolj9tpsrtcu.apps.googleusercontent.com" 

};
//console.log("STAGE", process.env.REACT_APP_STAGE);